<template>
  <div class="content" v-html="this.privacy_content"></div>
  <Spinner v-show="processing" />
</template>

<script>
export default {
  name: "Privacy",
  metaInfo() {
    return {
      title: this.$t("meta.title") + " :: " + this.$t("meta.privacy"),
      og: {
        title: this.$t("meta.title") + " :: " + this.$t("meta.privacy"),
      },
    };
  },
  data: function () {
    return {
      process_queue: [],
      privacy_content: "",
    };
  },
  computed: {
    processing() {
      if (this.process_queue.length <= 0) return false;
      else return true;
    },
  },
  mounted() {
    this.load_policy_data();
  },
  methods: {
    // 규정 정보 가져오기
    load_policy_data: function () {
      let self = this;
      self.process_queue.push(1);

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/policy/get",
          {},
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          let data = response.data;

          if (typeof data.PRIVACY != "undefined") {
            self.privacy_content = data.PRIVACY.policy_content;
          }
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(function () {
          self.process_queue.pop();
        }); //-- axios
    }, //-- load_policy_data
  },
};
</script>
